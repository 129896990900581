import * as userActions from "./yaadein.action";

export const yaadeinFeatureKey = "yaadeinInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    yaadeinList: [],
    yaadeinFullList: [],
    yaadeinListTotal: 0,
    yaadeinDetail: {}
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {

        // case userActions.UPDATE_USER_REQUEST:
        //     return {
        //         ...state,
        //         loading: true
        //     }

        // case userActions.UPDATE_USER_SUCCESS:
        //     let findIndex = state.yaadeinList.findIndex(o => o._id === payload.data._id);
        //     state.yaadeinList.splice(findIndex, 1, payload.data);
        //     console.log('payload.data', payload.data, "state.yaadeinList", state.yaadeinList)
        //     return {
        //         ...state,
        //         loading: false
        //     }

        // case userActions.UPDATE_USER_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         errorMsg: payload
        //     }

        // case userActions.DELETE_MUSIC_REQUEST:
        //     return {
        //         ...state,
        //         loading: true
        //     }

        // case userActions.DELETE_MUSIC_SUCCESS:
        //     const removeArr = state.yaadeinList.filter(o => o._id !== payload);
        //     return {
        //         ...state,
        //         loading: false,
        //         yaadeinList: removeArr
        //     }

        // case userActions.DELETE_MUSIC_FAILURE:
        //     return {
        //         ...state,
        //         loading: false,
        //         errorMsg: payload
        //     }

        case userActions.YAADEIN_DETAIL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.YAADEIN_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                yaadeinDetail: payload.data[0]
            }

        case userActions.YAADEIN_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case userActions.LIST_YAADEIN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.LIST_YAADEIN_SUCCESS:
            return {
                ...state,
                loading: false,
                yaadeinList: payload.data,
                yaadeinFullList: payload.fullListOfMyYaadeins,
                yaadeinListTotal: payload.total
            }

        case userActions.LIST_YAADEIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case userActions.LIST_FULL_YAADEIN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.LIST_FULL_YAADEIN_SUCCESS:
            return {
                ...state,
                loading: false,
                yaadeinFullList: payload.data
            }

        case userActions.LIST_FULL_YAADEIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};