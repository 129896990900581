import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "react-bootstrap";
import CustomModal from "../ui-elements/CustomModal";

  const Navbar = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const navigate = useNavigate();
  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  const toggleRightSidebar = () => {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

    return (
      <>
        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <Link className="navbar-brand brand-logo" to="/">
              <img src={require("../../../assets/images/logo1.png")} alt="logo" />
            </Link>
            <Link className="navbar-brand brand-logo-mini" to="/">
              <img
                src={require("../../../assets/images/logo-mini.png")}
                alt="logo"
              />
            </Link>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-stretch">
            <button
              className="navbar-toggler navbar-toggler align-self-center"
              type="button"
              onClick={() =>
                document.body.classList.toggle("sidebar-icon-only")
              }
            >
              <span className="mdi mdi-menu"></span>
            </button>
            {/* <div className="search-field d-none d-md-block">
              <form className="d-flex align-items-center h-100" action="#">
                <div className="input-group">
                  <div className="input-group-prepend bg-transparent">
                    <i className="input-group-text border-0 mdi mdi-magnify"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control bg-transparent border-0"
                    placeholder="Search projects"
                  />
                </div>
              </form>
            </div> */}

            {/* <button
              className="navbar-toggler navbar-toggler-right align-self-center ml-auto navbar-logout-btn"
              type="button"
              onClick={() => setModalShow(true)}
            > */}
            <CustomModal confirmOnClick={handleLogout} btnClass='mdi-logout mx-2 text-primary ml-auto align-self-center navbar-logout-btn' modalBodyContent='Are you sure want to Logout!' />
              {/* <i onClick={() => setModalShow(true)} className="mdi mdi-logout mx-2 text-primary ml-auto align-self-center navbar-logout-btn"></i> */}
            {/* </button> */}
            <button
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              onClick={toggleOffcanvas}
            >
              <span className="mdi mdi-menu"></span>
            </button>
          </div>
        </nav>
        <Modal
      show={modalShow}
      size="sx"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <h4>Are you sure want to logout!</h4>
      </ModalBody>
      <Modal.Footer>
        <button type="button" className="btn btn-light btn-fw" onClick={() => setModalShow(false)}>Not now</button>
        <button type="button" className="btn btn-primary btn-fw">Confirm</button>
      </Modal.Footer>
    </Modal>
      </>
    );
}

export default Navbar;
