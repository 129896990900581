import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const CREATE_MUSIC_REQUEST = "CREATE_MUSIC_REQUEST";
export const CREATE_MUSIC_SUCCESS = "CREATE_MUSIC_SUCCESS";
export const CREATE_MUSIC_FAILURE = "CREATE_MUSIC_FAILURE";

export const UPDATE_MUSIC_REQUEST = "UPDATE_MUSIC_REQUEST";
export const UPDATE_MUSIC_SUCCESS = "UPDATE_MUSIC_SUCCESS";
export const UPDATE_MUSIC_FAILURE = "UPDATE_MUSIC_FAILURE";

export const DELETE_MUSIC_REQUEST = "DELETE_MUSIC_REQUEST";
export const DELETE_MUSIC_SUCCESS = "DELETE_MUSIC_SUCCESS";
export const DELETE_MUSIC_FAILURE = "DELETE_MUSIC_FAILURE";

export const LIST_MUSIC_REQUEST = "LIST_MUSIC_REQUEST";
export const LIST_MUSIC_SUCCESS = "LIST_MUSIC_SUCCESS";
export const LIST_MUSIC_FAILURE = "LIST_MUSIC_FAILURE";

export const createMusic = (data, toggle, setData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_MUSIC_REQUEST });
            // upload icon on s3
            const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/s3/upload/yaadein/musics/v1`, {
                sFileName: data.icon.file.name,
                sContentType: data.icon.file.type
            }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            const imageUrl = imgRes.data.data.sUrl
            const sImage = imgRes.data.data.sPath
            await axios.put(imageUrl, data.icon.file, { headers: { "Content-Type": data.icon.file.type } });

            const url = `${baseUrl}/yaadein/musics/create/v1`;
            const response = await axios.post(url, { ...data, src: sImage }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: CREATE_MUSIC_SUCCESS, payload: { data: { ...response.data.data, categoryDetails: data.categoryDetails } } });
            toast("Music Created Successfully.");
            toggle();
            setData({
                name: "",
                icon: "",
                isInput: false,
                placeholder: ""
            });
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_MUSIC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateMusic = (id, data, toggle, setData, setSelectedData) => {
    console.log('data inside a updateMusic', data)
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_MUSIC_REQUEST });
            let icon = "";

            // upload icon on s3
            if (data && data.icon && data.icon.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/s3/upload/yaadein/musics/v1`, {
                    sFileName: data.icon.file.name,
                    sContentType: data.icon.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                const imageUrl = imgRes.data.data.sUrl
                icon = imgRes.data.data.sPath
                await axios.put(imageUrl, data.icon.file, { headers: { "Content-Type": data.icon.file.type } });
            }

            const url = `${baseUrl}/yaadein/musics/update/${id}/v1`;
            const response = await axios.put(url, { ...data, src: icon === "" ? data.icon : icon }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: UPDATE_MUSIC_SUCCESS, payload: { data: { ...response.data.data, categoryDetails: data.categoryDetails } } });
            toast("Music Updated Successfully.");
            toggle();
            setData({
                name: "",
                icon: "",
                isInput: false,
                placeholder: "",
            });
            setSelectedData({});
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_MUSIC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const deleteMusic = (id, selectedData, toggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_MUSIC_REQUEST });
            const url = `${baseUrl}/yaadein/musics/delete/${id}/v1`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: DELETE_MUSIC_SUCCESS, payload: id });
            toast("Music Deleted Successfully.");
            toggle();
            selectedData({});
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_MUSIC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const listMusic = (skip, search, category) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_MUSIC_REQUEST });
            const url = `${baseUrl}/yaadein/musics/admin/list/v1?skip=${skip}&search=${search}&category=${category}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: LIST_MUSIC_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_MUSIC_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};