import * as adminActions from "./admin.action";

export const adminFeatureKey = "adminInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    loginUser: {}
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case adminActions.ADMIN_LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case adminActions.ADMIN_LOGIN_SUCCESS:
            localStorage.setItem("token", payload.token);
            return {
                ...state,
                loading: false,
                loginUser: payload.data
            }

        case adminActions.ADMIN_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case adminActions.GET_MY_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case adminActions.GET_MY_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                loginUser: payload.data
            }

        case adminActions.GET_MY_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};