import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const CREATE_MUSIC_CATEGORY_REQUEST = "CREATE_MUSIC_CATEGORY_REQUEST";
export const CREATE_MUSIC_CATEGORY_SUCCESS = "CREATE_MUSIC_CATEGORY_SUCCESS";
export const CREATE_MUSIC_CATEGORY_FAILURE = "CREATE_MUSIC_CATEGORY_FAILURE";

export const UPDATE_MUSIC_CATEGORY_REQUEST = "UPDATE_MUSIC_CATEGORY_REQUEST";
export const UPDATE_MUSIC_CATEGORY_SUCCESS = "UPDATE_MUSIC_CATEGORY_SUCCESS";
export const UPDATE_MUSIC_CATEGORY_FAILURE = "UPDATE_MUSIC_CATEGORY_FAILURE";

export const DELETE_MUSIC_CATEGORY_REQUEST = "DELETE_MUSIC_CATEGORY_REQUEST";
export const DELETE_MUSIC_CATEGORY_SUCCESS = "DELETE_MUSIC_CATEGORY_SUCCESS";
export const DELETE_MUSIC_CATEGORY_FAILURE = "DELETE_MUSIC_CATEGORY_FAILURE";

export const LIST_MUSIC_CATEGORY_REQUEST = "LIST_MUSIC_CATEGORY_REQUEST";
export const LIST_MUSIC_CATEGORY_SUCCESS = "LIST_MUSIC_CATEGORY_SUCCESS";
export const LIST_MUSIC_CATEGORY_FAILURE = "LIST_MUSIC_CATEGORY_FAILURE";

export const createMusicCategory = (data, toggle, setData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_MUSIC_CATEGORY_REQUEST });
            const url = `${baseUrl}/yaadein/musics/create/category/v1`;
            const response = await axios.post(url, { ...data }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: CREATE_MUSIC_CATEGORY_SUCCESS, payload: response.data });
            toast("Music Category Created Successfully.");
            toggle();
            setData({
                name: "",
                icon: "",
                isInput: false,
                placeholder: ""
            });
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_MUSIC_CATEGORY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateMusicCategory = (id, data, toggle, setData, setSelectedData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_MUSIC_CATEGORY_REQUEST });
            let icon = "";

            const url = `${baseUrl}/yaadein/musics/update/category/${id}/v1`;
            const response = await axios.put(url, { ...data }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: UPDATE_MUSIC_CATEGORY_SUCCESS, payload: response.data });
            toast("Music Updated Successfully.");
            toggle();
            setData({
                name: "",
                icon: "",
                isInput: false,
                placeholder: "",
            });
            setSelectedData({});
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_MUSIC_CATEGORY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const deleteMusicCategory = (id, selectedData, toggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_MUSIC_CATEGORY_REQUEST });
            const url = `${baseUrl}/yaadein/musics/delete/category/${id}/v1`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: DELETE_MUSIC_CATEGORY_SUCCESS, payload: id });
            toast("Music Deleted Successfully.");
            toggle();
            selectedData({});
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_MUSIC_CATEGORY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const listMusicCategory = (page, search) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_MUSIC_CATEGORY_REQUEST });
            const url = `${baseUrl}/yaadein/musics/admin/list/category/v1?skip=${page}&search=${search}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: LIST_MUSIC_CATEGORY_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_MUSIC_CATEGORY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};