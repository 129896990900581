import * as occasionActions from "./occasion.action";

export const occasionFeatureKey = "occasionInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    occasionList: [],
    occasionFullList: [],
    occasionListTotal: 0
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case occasionActions.CREATE_OCCASION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case occasionActions.CREATE_OCCASION_SUCCESS:
            let createArr = state.occasionList;
            createArr.unshift(payload.data);
            return {
                ...state,
                loading: false,
                occasionList: createArr
            }

        case occasionActions.CREATE_OCCASION_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case occasionActions.UPDATE_OCCASION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case occasionActions.UPDATE_OCCASION_SUCCESS:
            let findIndex = state.occasionList.findIndex(o => o._id === payload.data._id);
            state.occasionList.splice(findIndex, 1, payload.data);
            return {
                ...state,
                loading: false
            }

        case occasionActions.UPDATE_OCCASION_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case occasionActions.DELETE_OCCASION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case occasionActions.DELETE_OCCASION_SUCCESS:
            const removeArr = state.occasionList.filter(o => o._id !== payload);
            return {
                ...state,
                loading: false,
                occasionList: removeArr
            }

        case occasionActions.DELETE_OCCASION_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case occasionActions.LIST_OCCASION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case occasionActions.LIST_OCCASION_SUCCESS:
            return {
                ...state,
                loading: false,
                occasionList: payload.data,
                occasionFullList: payload.fullList,
                occasionListTotal: payload.total
            }

        case occasionActions.LIST_OCCASION_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};