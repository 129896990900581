import * as imageActions from "./image.action";

export const imageFeatureKey = "imageInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    imageList: [],
    imageListTotal: 0
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case imageActions.CREATE_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case imageActions.CREATE_IMAGE_SUCCESS:
            let createArr = state.imageList;
            createArr.unshift(payload.data);
            return {
                ...state,
                loading: false,
                imageList: createArr
            }

        case imageActions.CREATE_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case imageActions.UPDATE_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case imageActions.UPDATE_IMAGE_SUCCESS:
            let findIndex = state.imageList.findIndex(o => o._id === payload.data._id);
            state.imageList.splice(findIndex, 1, payload.data);
            return {
                ...state,
                loading: false
            }

        case imageActions.UPDATE_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case imageActions.DELETE_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case imageActions.DELETE_IMAGE_SUCCESS:
            const removeArr = state.imageList.filter(o => o._id !== payload);
            return {
                ...state,
                loading: false,
                imageList: removeArr
            }

        case imageActions.DELETE_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case imageActions.LIST_IMAGE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case imageActions.LIST_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                imageListTotal: payload.total,
                imageList: payload.data
            }

        case imageActions.LIST_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};