import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const NavLink = ({
  commonUrl,
  isPathActive,
  iconClass,
  title,
  url,
  child = false,
}) => {
  const [subdirectory, setSubdirectory] = useState(false);
  const { pathname } = useLocation();
  return (
    <>
      <li
        className={
          pathname.includes(commonUrl) ? "nav-item active" : "nav-item"
        }
      >
        <Link
          className="nav-link"
          to={url}
          onClick={() => setSubdirectory(!subdirectory)}
        >
          <span className="menu-title">{title}</span>
          <i className={`mdi ${iconClass} menu-icon`}></i>
        </Link>
      </li>
      {child && subdirectory && (
        <ul className="nav">
          {child?.map((c) => {
            return (
              <>
                {
                  c?.visible && (
                    <>
                      <li
                        className={
                          pathname.includes(c.url) ? "nav-item active" : "nav-item"
                        }
                      >
                        <Link className="nav-link py-3" to={c.url}>
                          <span className="menu-title">{c.title}</span>
                          <i className={`mdi ${c.iconClass}`}></i>
                        </Link>
                      </li>
                    </>
                  )
                }
              </>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default NavLink;
