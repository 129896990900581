import * as instructionActions from "./Instruction.actions";

export const InstructionInformationFeatureKey = "InstructionInfo";

const initialState = {
    loading: false,
    isCreate: false,
    errorMessage: "",
    instructionInviteDetail: {}
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case instructionActions.SEND_INSTRUCTION_INVITE_REQUEST:
            return {
                ...state,
                loading: true,
                isCreate: false
            }

        case instructionActions.SEND_INSTRUCTION_INVITE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreate: true
            }

        case instructionActions.SEND_INSTRUCTION_INVITE_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                isCreate: false,
                errorMessage: payload
            }

        case instructionActions.GET_INSTRUCTION_INVITE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case instructionActions.GET_INSTRUCTION_INVITE_SUCCESS:
            console.log('payload', payload)
            return {
                ...state,
                loading: false,
                instructionInviteDetail: payload.data || {},
            }

        case instructionActions.GET_INSTRUCTION_INVITE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: payload
            }

            case instructionActions.SEND_DYNAMIC_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                isCreate: false
            }

        case instructionActions.SEND_DYNAMIC_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreate: true
            }

        case instructionActions.SEND_DYNAMIC_DATA_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                isCreate: false,
                errorMessage: payload
            }

        case instructionActions.GET_DYNAMIC_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }

        case instructionActions.GET_DYNAMIC_DATA_SUCCESS:
            console.log('payload', payload)
            return {
                ...state,
                loading: false,
                dynamicDataDetail: payload.data || {},
            }

        case instructionActions.GET_DYNAMIC_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: payload
            }

        default: return state;
    }
};