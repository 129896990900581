import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const CREATE_IMAGE_REQUEST = "CREATE_IMAGE_REQUEST";
export const CREATE_IMAGE_SUCCESS = "CREATE_IMAGE_SUCCESS";
export const CREATE_IMAGE_FAILURE = "CREATE_IMAGE_FAILURE";

export const UPDATE_IMAGE_REQUEST = "UPDATE_IMAGE_REQUEST";
export const UPDATE_IMAGE_SUCCESS = "UPDATE_IMAGE_SUCCESS";
export const UPDATE_IMAGE_FAILURE = "UPDATE_IMAGE_FAILURE";

export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAILURE = "DELETE_IMAGE_FAILURE";

export const LIST_IMAGE_REQUEST = "LIST_IMAGE_REQUEST";
export const LIST_IMAGE_SUCCESS = "LIST_IMAGE_SUCCESS";
export const LIST_IMAGE_FAILURE = "LIST_IMAGE_FAILURE";

export const createImage = (data, toggle, setData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_IMAGE_REQUEST });
            // upload icon on s3
            const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/s3/upload/yaadein/images/v1`, {
                sFileName: data.icon.file.name,
                sContentType: data.icon.file.type
            }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            const imageUrl = imgRes.data.data.sUrl
            const sImage = imgRes.data.data.sPath
            await axios.put(imageUrl, data.icon.file, { headers: { "Content-Type": data.icon.file.type } });

            const url = `${baseUrl}/yaadein/images/create/v1`;
            const response = await axios.post(url, { ...data, src: sImage }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: CREATE_IMAGE_SUCCESS, payload: response.data });
            toast("image Created Successfully.");
            toggle();
            setData({
                name: "",
                icon: "",
                isInput: false,
                placeholder: ""
            });
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_IMAGE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateImage = (id, data, toggle, setData, setSelectedData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_IMAGE_REQUEST });
            let icon = "";

            // upload icon on s3
            if (data && data.icon && data.icon.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/s3/upload/yaadein/images/v1`, {
                    sFileName: data.icon.file.name,
                    sContentType: data.icon.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                const imageUrl = imgRes.data.data.sUrl
                icon = imgRes.data.data.sPath
                await axios.put(imageUrl, data.icon.file, { headers: { "Content-Type": data.icon.file.type } });
            }

            const url = `${baseUrl}/yaadein/images/update/${id}/v1`;
            const response = await axios.put(url, { ...data, src: icon === "" ? data.icon : icon }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: UPDATE_IMAGE_SUCCESS, payload: response.data });
            toast("Image Updated Successfully.");
            toggle();
            setData({
                name: "",
                icon: "",
                isInput: false,
                placeholder: "",
            });
            setSelectedData({});
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_IMAGE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const deleteImage = (id, selectedData, toggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_IMAGE_REQUEST });
            const url = `${baseUrl}/yaadein/images/delete/${id}/v1`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: DELETE_IMAGE_SUCCESS, payload: id });
            toast("Image Deleted Successfully.");
            toggle();
            selectedData({});
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_IMAGE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const listImages = (page) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_IMAGE_REQUEST });
            const url = `${baseUrl}/yaadein/images/admin/list/v1?skip=${page}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: LIST_IMAGE_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_IMAGE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};