import * as imageActions from "./gif.action";

export const gifFeatureKey = "gifInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    gifList: [],
    gifListTotal: 0
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case imageActions.CREATE_GIF_REQUEST:
            return {
                ...state,
                loading: true
            }

        case imageActions.CREATE_GIF_SUCCESS:
            let createArr = state.gifList;
            createArr.unshift(payload.data);
            return {
                ...state,
                loading: false,
                gifList: createArr
            }

        case imageActions.CREATE_GIF_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case imageActions.UPDATE_GIF_REQUEST:
            return {
                ...state,
                loading: true
            }

        case imageActions.UPDATE_GIF_SUCCESS:
            let findIndex = state.gifList.findIndex(o => o._id === payload.data._id);
            state.gifList.splice(findIndex, 1, payload.data);
            return {
                ...state,
                loading: false
            }

        case imageActions.UPDATE_GIF_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case imageActions.DELETE_GIF_REQUEST:
            return {
                ...state,
                loading: true
            }

        case imageActions.DELETE_GIF_SUCCESS:
            const removeArr = state.gifList.filter(o => o._id !== payload);
            return {
                ...state,
                loading: false,
                gifList: removeArr
            }

        case imageActions.DELETE_GIF_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case imageActions.LIST_GIF_REQUEST:
            return {
                ...state,
                loading: true
            }

        case imageActions.LIST_GIF_SUCCESS:
            return {
                ...state,
                loading: false,
                gifList: payload.data,
                gifListTotal: payload.total
            }

        case imageActions.LIST_GIF_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};