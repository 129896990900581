import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const SEND_INSTRUCTION_INVITE_REQUEST = 'SEND_INSTRUCTION_INVITE_REQUEST';
export const SEND_INSTRUCTION_INVITE_REQUEST_SUCCESS = 'SEND_INSTRUCTION_INVITE_REQUEST_SUCCESS';
export const SEND_INSTRUCTION_INVITE_REQUEST_FAILURE = 'SEND_INSTRUCTION_INVITE_REQUEST_FAILURE';

export const GET_INSTRUCTION_INVITE_REQUEST = 'GET_INSTRUCTION_INVITE_REQUEST';
export const GET_INSTRUCTION_INVITE_SUCCESS = 'GET_INSTRUCTION_INVITE_SUCCESS';
export const GET_INSTRUCTION_INVITE_FAILURE = 'GET_INSTRUCTION_INVITE_FAILURE';

export const SEND_DYNAMIC_DATA_REQUEST = 'SEND_DYNAMIC_DATA_REQUEST';
export const SEND_DYNAMIC_DATA_REQUEST_SUCCESS = 'SEND_DYNAMIC_DATA_REQUEST_SUCCESS';
export const SEND_DYNAMIC_DATA_REQUEST_FAILURE = 'SEND_DYNAMIC_DATA_REQUEST_FAILURE';

export const GET_DYNAMIC_DATA_REQUEST = 'GET_DYNAMIC_DATA_REQUEST';
export const GET_DYNAMIC_DATA_SUCCESS = 'GET_DYNAMIC_DATA_SUCCESS';
export const GET_DYNAMIC_DATA_FAILURE = 'GET_DYNAMIC_DATA_FAILURE';

export const sendNotificationToAllUsers = (instruction) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_INSTRUCTION_INVITE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/instruction/update/v1`;
            const res = await axios.put(url, {
                instruction
            }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: SEND_INSTRUCTION_INVITE_REQUEST_SUCCESS, payload: res.data });
            toast("Instruction invite updated successfully");
            try {
                dispatch({ type: GET_INSTRUCTION_INVITE_REQUEST });
                const url = `${process.env.REACT_APP_BASE_URL}/instruction/admin/get/v1`;
                const res = await axios.get(url, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: GET_INSTRUCTION_INVITE_SUCCESS, payload: res.data });
            } catch (error) {
                console.error(error);
                dispatch({ type: GET_INSTRUCTION_INVITE_FAILURE, payload: error.message });
                toast(error.response.data.message);
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: SEND_INSTRUCTION_INVITE_REQUEST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getInstructionInformation = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_INSTRUCTION_INVITE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/instruction/admin/get/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_INSTRUCTION_INVITE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_INSTRUCTION_INVITE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const sendDynamicData = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_DYNAMIC_DATA_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/dynamic/update/v1`;
            const res = await axios.put(url, {
                ...data
            }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: SEND_DYNAMIC_DATA_REQUEST_SUCCESS, payload: res.data });
            toast("Dynamic details updated successfully");
            try {
                dispatch({ type: GET_DYNAMIC_DATA_REQUEST });
                const url = `${process.env.REACT_APP_BASE_URL}/dynamic/admin/get/v1`;
                const res = await axios.get(url, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: GET_DYNAMIC_DATA_SUCCESS, payload: res.data });
            } catch (error) {
                console.error(error);
                dispatch({ type: GET_DYNAMIC_DATA_FAILURE, payload: error.message });
                toast(error.response.data.message);
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: SEND_DYNAMIC_DATA_REQUEST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getDynamicData = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_DYNAMIC_DATA_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/dynamic/admin/get/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_DYNAMIC_DATA_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_DYNAMIC_DATA_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};