import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE";

export const GET_MY_PROFILE_REQUEST = "GET_MY_PROFILE_REQUEST";
export const GET_MY_PROFILE_SUCCESS = "GET_MY_PROFILE_SUCCESS";
export const GET_MY_PROFILE_FAILURE = "GET_MY_PROFILE_FAILURE";

export const adminLogin = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADMIN_LOGIN_REQUEST });
            const url = `${baseUrl}/auth/login/admin/v1`;
            const response = await axios.post(url, data);
            dispatch({ type: ADMIN_LOGIN_SUCCESS, payload: response.data });
            toast("Login Successfully");
            navigate('/');
        } catch (error) {
            console.error(error);
            dispatch({ type: ADMIN_LOGIN_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getMyProfile = (navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_MY_PROFILE_REQUEST });
            const url = `${baseUrl}/user/admin/get/my/profile/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: GET_MY_PROFILE_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_MY_PROFILE_FAILURE, payload: error.message });
            if (error?.response?.status === 401) {
                localStorage.removeItem("token");
                navigate("/login");
            }
            toast(error?.response?.data?.message);
        }
    }
};