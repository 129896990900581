import * as musicActions from "./music.action";

export const musicFeatureKey = "musicInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    musicList: [],
    musicListCount: 0
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case musicActions.CREATE_MUSIC_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case musicActions.CREATE_MUSIC_SUCCESS:
            console.log('payload.data', payload.data)
            let createArr = state.musicList;
            createArr.unshift(payload.data);
            console.log('payload.data', payload.data)
            return {
                ...state,
                loading: false,
                musicList: createArr
            }

        case musicActions.CREATE_MUSIC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case musicActions.UPDATE_MUSIC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case musicActions.UPDATE_MUSIC_SUCCESS:
            let findIndex = state.musicList.findIndex(o => o._id === payload.data._id);
            state.musicList.splice(findIndex, 1, payload.data);
            console.log('payload.data', payload.data, "state.musicList", state.musicList)
            return {
                ...state,
                loading: false
            }

        case musicActions.UPDATE_MUSIC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case musicActions.DELETE_MUSIC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case musicActions.DELETE_MUSIC_SUCCESS:
            const removeArr = state.musicList.filter(o => o._id !== payload);
            return {
                ...state,
                loading: false,
                musicList: removeArr
            }

        case musicActions.DELETE_MUSIC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case musicActions.LIST_MUSIC_REQUEST:
            return {
                ...state,
                loading: true
            }

        case musicActions.LIST_MUSIC_SUCCESS:
            console.log('payload', payload)
            return {
                ...state,
                loading: false,
                musicList: payload.data,
                musicListCount: payload.total
            }

        case musicActions.LIST_MUSIC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};