import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const CREATE_OCCASION_REQUEST = "CREATE_OCCASION_REQUEST";
export const CREATE_OCCASION_SUCCESS = "CREATE_OCCASION_SUCCESS";
export const CREATE_OCCASION_FAILURE = "CREATE_OCCASION_FAILURE";

export const UPDATE_OCCASION_REQUEST = "UPDATE_OCCASION_REQUEST";
export const UPDATE_OCCASION_SUCCESS = "UPDATE_OCCASION_SUCCESS";
export const UPDATE_OCCASION_FAILURE = "UPDATE_OCCASION_FAILURE";

export const DELETE_OCCASION_REQUEST = "DELETE_OCCASION_REQUEST";
export const DELETE_OCCASION_SUCCESS = "DELETE_OCCASION_SUCCESS";
export const DELETE_OCCASION_FAILURE = "DELETE_OCCASION_FAILURE";

export const LIST_OCCASION_REQUEST = "LIST_OCCASION_REQUEST";
export const LIST_OCCASION_SUCCESS = "LIST_OCCASION_SUCCESS";
export const LIST_OCCASION_FAILURE = "LIST_OCCASION_FAILURE";

export const createOccasion = (data, toggle, setData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_OCCASION_REQUEST });
            // upload icon on s3
            const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/s3/upload/occasion/icon/v1`, {
                sFileName: data.icon.file.name,
                sContentType: data.icon.file.type
            }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            const imageUrl = imgRes.data.data.sUrl
            const sImage = imgRes.data.data.sPath
            await axios.put(imageUrl, data.icon.file, { headers: { "Content-Type": data.icon.file.type } });

            // upload occasionImage on s3
            // const occasionImageRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/s3/upload/occasion/bg/v1`, {
            //     sFileName: data.occasionImage.file.name,
            //     sContentType: data.occasionImage.file.type
            // }, {
            //     headers: {
            //         Authorization: localStorage.getItem("token")
            //     }
            // });
            // const occasionImageImageUrl = occasionImageRes.data.data.sUrl
            // const occasionImageSImage = occasionImageRes.data.data.sPath
            // await axios.put(occasionImageImageUrl, data.occasionImage.file, { headers: { "Content-Type": data.occasionImage.file.type } });

            let occasionThemes = []
            if (data && data.occasionThemes && data.occasionThemes.length) {
                for (let index = 0; index < data.occasionThemes.length; index++) {
                    const element = data.occasionThemes[index];
                    
                    const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/s3/upload/occasion/bg/v1`, {
                        sFileName: element.file.name,
                        sContentType: element.file.type
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token")
                        }
                    });
                    const imageUrl = imgRes.data.data.sUrl
                    // occasionImage = imgRes.data.data.sPath
                    occasionThemes.push({
                        url: imgRes.data.data.sPath,
                        checked: element.checked
                    })
                    await axios.put(imageUrl, element.file, { headers: { "Content-Type": element.file.type } });
                }
            }

            const url = `${baseUrl}/occasion/create/v1`;
            const response = await axios.post(url, { ...data, icon: sImage, occasionThemes: occasionThemes }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: CREATE_OCCASION_SUCCESS, payload: response.data });
            toast("Occasion Created Successfully.");
            toggle();
            setData({
                name: "",
                icon: "",
                isInput: false,
                placeholder: "",
                occasionImage: ""
            });
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_OCCASION_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateOccasion = (id, data, toggle, setData, setSelectedData) => {
    return async (dispatch) => {
        try {
            console.log('data', data)
            dispatch({ type: UPDATE_OCCASION_REQUEST });
            let icon = "";
            let occasionImage = "";

            // upload icon on s3
            if (data && data.icon && data.icon.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/s3/upload/occasion/icon/v1`, {
                    sFileName: data.icon.file.name,
                    sContentType: data.icon.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                const imageUrl = imgRes.data.data.sUrl
                icon = imgRes.data.data.sPath
                await axios.put(imageUrl, data.icon.file, { headers: { "Content-Type": data.icon.file.type } });
            }

            // upload occasionImage on s3
            if (data && data.occasionImage && data.occasionImage.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/s3/upload/occasion/bg/v1`, {
                    sFileName: data.occasionImage.file.name,
                    sContentType: data.occasionImage.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                const imageUrl = imgRes.data.data.sUrl
                occasionImage = imgRes.data.data.sPath
                await axios.put(imageUrl, data.occasionImage.file, { headers: { "Content-Type": data.occasionImage.file.type } });
            }

            // upload OccasionTheme on s3
            let occasionTheme = []
            if (data && data.occasionThemes && data.occasionThemes.length) {
                for (let index = 0; index < data.occasionThemes.length; index++) {
                    const element = data.occasionThemes[index];
                    
                    if (element?.file?.name) {
                        const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/s3/upload/occasion/bg/v1`, {
                            sFileName: element.file.name,
                            sContentType: element.file.type
                        }, {
                            headers: {
                                Authorization: localStorage.getItem("token")
                            }
                        });
                        const imageUrl = imgRes.data.data.sUrl
                        // occasionImage = imgRes.data.data.sPath
                        occasionTheme.push({
                            url: imgRes.data.data.sPath,
                            checked: element.checked
                        })
                        await axios.put(imageUrl, element.file, { headers: { "Content-Type": element.file.type } });
                    } else {
                        occasionTheme.push({
                            ...element
                        })
                    }
                }
            }

            const url = `${baseUrl}/occasion/update/${id}/v1`;
            const response = await axios.put(url, { ...data, icon: icon === "" ? data.icon : icon, occasionThemes: occasionTheme}, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: UPDATE_OCCASION_SUCCESS, payload: response.data });
            toast("Occasion Updated Successfully.");
            toggle();
            setData({
                name: "",
                icon: "",
                isInput: false,
                placeholder: "",
                occasionImage: ""
            });
            setSelectedData({});
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_OCCASION_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const deleteOccasion = (id, selectedData, toggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_OCCASION_REQUEST });
            const url = `${baseUrl}/occasion/delete/${id}/v1`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: DELETE_OCCASION_SUCCESS, payload: id });
            toast("Occasion Deleted Successfully.");
            toggle();
            selectedData({});
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_OCCASION_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const listOccasion = (page, search) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_OCCASION_REQUEST });
            const url = `${baseUrl}/occasion/list/v1?skip=${page}&search=${search}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: LIST_OCCASION_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_OCCASION_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};