import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const UPDATE_TUTORIAL_REQUEST = "UPDATE_TUTORIAL_REQUEST";
export const UPDATE_TUTORIAL_SUCCESS = "UPDATE_TUTORIAL_SUCCESS";
export const UPDATE_TUTORIAL_FAILURE = "UPDATE_TUTORIAL_FAILURE";

export const LIST_TUTORIAL_REQUEST = "LIST_TUTORIAL_REQUEST";
export const LIST_TUTORIAL_SUCCESS = "LIST_TUTORIAL_SUCCESS";
export const LIST_TUTORIAL_FAILURE = "LIST_TUTORIAL_FAILURE";

export const updateTutorial = (data) => {
    return async (dispatch) => {
        try {
            let icon = "";
            if (data && data.icon && data.icon.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/s3/upload/tutorial/video/v1`, {
                    sFileName: data.icon.file.name,
                    sContentType: data.icon.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                const imageUrl = imgRes.data.data.sUrl
                icon = imgRes.data.data.sPath
                await axios.put(imageUrl, data.icon.file, { headers: { "Content-Type": data.icon.file.type } });
            }
            let iconMobile = "";
            if (data && data.iconMobile && data.iconMobile.file) {
                const imgRes = await axios.post(`${process.env.REACT_APP_BASE_URL}/s3/upload/tutorial/video/v1`, {
                    sFileName: data.iconMobile.file.name,
                    sContentType: data.iconMobile.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                const imageUrl = imgRes.data.data.sUrl
                iconMobile = imgRes.data.data.sPath
                await axios.put(imageUrl, data.iconMobile.file, { headers: { "Content-Type": data.iconMobile.file.type } });
            }
            dispatch({ type: UPDATE_TUTORIAL_REQUEST });

            const updatedUrlMobile = iconMobile === "" ? data.urlMobile : `https://asset.yaadein.co/${iconMobile}`
            const updatedUrlWeb = icon === "" ? data.url : `https://asset.yaadein.co/${icon}`
            const url = `${baseUrl}/tutorial/video/update/v1`;
            const response = await axios.post(url, { ...data, urlMobile: updatedUrlMobile, url: updatedUrlWeb }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: UPDATE_TUTORIAL_SUCCESS, payload: response.data });
            toast("Tutorial is updated successfully.");
            try {
                dispatch({ type: LIST_TUTORIAL_REQUEST });
                const url = `${baseUrl}/tutorial/video/details/admin/v1`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                dispatch({ type: LIST_TUTORIAL_SUCCESS, payload: response.data });
            } catch (error) {
                console.error(error);
                dispatch({ type: LIST_TUTORIAL_FAILURE, payload: error.message });
                toast(error?.response?.data?.message);
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_TUTORIAL_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const listTutorial = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_TUTORIAL_REQUEST });
            const url = `${baseUrl}/tutorial/video/details/admin/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: LIST_TUTORIAL_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_TUTORIAL_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};