import * as instructionActions from "./Setting.actions";

export const SettingInformationFeatureKey = "SettingInfo";

const initialState = {
    loading: false,
    isCreate: false,
    errorMessage: "",
    settingDetail: {}
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case instructionActions.UPDATE_SETTING_REQUEST:
            return {
                ...state,
                loading: true,
                isCreate: false
            }

        case instructionActions.UPDATE_SETTING_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreate: true
            }

        case instructionActions.UPDATE_SETTING_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                isCreate: false,
                errorMessage: payload
            }

        case instructionActions.GET_SETTING_REQUEST:
            return {
                ...state,
                loading: true
            }

        case instructionActions.GET_SETTING_SUCCESS:
            console.log('payload', payload)
            return {
                ...state,
                loading: false,
                settingDetail: payload.data || {},
            }

        case instructionActions.GET_SETTING_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: payload
            }

        default: return state;
    }
};