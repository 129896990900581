import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const LIST_YAADEIN_REQUEST = "LIST_YAADEIN_REQUEST";
export const LIST_YAADEIN_SUCCESS = "LIST_YAADEIN_SUCCESS";
export const LIST_YAADEIN_FAILURE = "LIST_YAADEIN_FAILURE";

export const LIST_FULL_YAADEIN_REQUEST = "LIST_FULL_YAADEIN_REQUEST";
export const LIST_FULL_YAADEIN_SUCCESS = "LIST_FULL_YAADEIN_SUCCESS";
export const LIST_FULL_YAADEIN_FAILURE = "LIST_FULL_YAADEIN_FAILURE";

export const YAADEIN_DETAIL_REQUEST = "YAADEIN_DETAIL_REQUEST";
export const YAADEIN_DETAIL_SUCCESS = "YAADEIN_DETAIL_SUCCESS";
export const YAADEIN_DETAIL_FAILURE = "YAADEIN_DETAIL_FAILURE";

export const UPDATE_YAADEIN_REQUEST = 'UPDATE_YAADEIN_REQUEST';
export const UPDATE_YAADEIN_SUCCESS = 'UPDATE_YAADEIN_SUCCESS';
export const UPDATE_YAADEIN_FAILURE = 'UPDATE_YAADEIN_FAILURE';

export const listYaadein = (skip, search, celebration, occasion, start, end) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_YAADEIN_REQUEST });
            const url = `${baseUrl}/yaadein/admin/list/v1?skip=${skip}&start=${start}&end=${end}&search=${search}&celebration=${celebration}&occasion=${occasion}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: LIST_YAADEIN_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_YAADEIN_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateYaadein = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_YAADEIN_REQUEST });
            const url = `${baseUrl}/yaadein/admin/yaadein/details/${id}/v1`;
            const response = await axios.put(url, { ...data }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: UPDATE_YAADEIN_SUCCESS, payload: response.data });
            try {
                dispatch({ type: YAADEIN_DETAIL_REQUEST });
                const url = `${baseUrl}/yaadein/admin/details/${id}/v1`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                dispatch({ type: YAADEIN_DETAIL_SUCCESS, payload: response.data });
            } catch (error) {
                console.error(error);
                dispatch({ type: YAADEIN_DETAIL_FAILURE, payload: error.message });
                toast(error?.response?.data?.message);
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_YAADEIN_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

// export const listFullYaadein = () => {
//     return async (dispatch) => {
//         try {
//             dispatch({ type: LIST_FULL_YAADEIN_REQUEST });
//             const url = `${baseUrl}/yaadein/admin/list/v2`;
//             const response = await axios.get(url, {
//                 headers: {
//                     Authorization: localStorage.getItem("token")
//                 }
//             });
//             dispatch({ type: LIST_FULL_YAADEIN_SUCCESS, payload: response.data });
//         } catch (error) {
//             console.error(error);
//             dispatch({ type: LIST_FULL_YAADEIN_FAILURE, payload: error.message });
//             toast(error?.response?.data?.message);
//         }
//     }
// };

export const getYaadeinId = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: YAADEIN_DETAIL_REQUEST });
            const url = `${baseUrl}/yaadein/admin/details/${id}/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: YAADEIN_DETAIL_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: YAADEIN_DETAIL_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};
