import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const UPDATE_SETTING_REQUEST = 'UPDATE_SETTING_REQUEST';
export const UPDATE_SETTING_REQUEST_SUCCESS = 'UPDATE_SETTING_REQUEST_SUCCESS';
export const UPDATE_SETTING_REQUEST_FAILURE = 'UPDATE_SETTING_REQUEST_FAILURE';

export const GET_SETTING_REQUEST = 'GET_SETTING_REQUEST';
export const GET_SETTING_SUCCESS = 'GET_SETTING_SUCCESS';
export const GET_SETTING_FAILURE = 'GET_SETTING_FAILURE';

export const updateVersions = (instruction) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_SETTING_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/setting/update/app/version/v1`;
            const res = await axios.put(url, {
                ...instruction
            }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_SETTING_REQUEST_SUCCESS, payload: res.data });
            toast("Instruction invite updated successfully");
            try {
                dispatch({ type: GET_SETTING_REQUEST });
                const url = `${process.env.REACT_APP_BASE_URL}/setting/admin/get/app/version/v1`;
                const res = await axios.get(url, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: GET_SETTING_SUCCESS, payload: res.data });
            } catch (error) {
                console.error(error);
                dispatch({ type: GET_SETTING_FAILURE, payload: error.message });
                toast(error.response.data.message);
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_SETTING_REQUEST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getSettingInformation = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_SETTING_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/setting/admin/get/app/version/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_SETTING_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_SETTING_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};
