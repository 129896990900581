import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const SEND_NOTIFICATION_TO_ALL_USERS_REQUEST = 'SEND_NOTIFICATION_TO_ALL_USERS_REQUEST';
export const SEND_NOTIFICATION_TO_ALL_USERS_SUCCESS = 'SEND_NOTIFICATION_TO_ALL_USERS_SUCCESS';
export const SEND_NOTIFICATION_TO_ALL_USERS_FAILURE = 'SEND_NOTIFICATION_TO_ALL_USERS_FAILURE';

export const GET_ALL_NOTIFICATION_REQUEST = 'GET_ALL_NOTIFICATION_REQUEST';
export const GET_ALL_NOTIFICATION_SUCCESS = 'GET_ALL_NOTIFICATION_SUCCESS';
export const GET_ALL_NOTIFICATION_FAILURE = 'GET_ALL_NOTIFICATION_FAILURE';

export const SEND_TIPS_NOTIFICATION_TO_ALL_USERS_REQUEST = 'SEND_TIPS_NOTIFICATION_TO_ALL_USERS_REQUEST';
export const SEND_TIPS_NOTIFICATION_TO_ALL_USERS_SUCCESS = 'SEND_TIPS_NOTIFICATION_TO_ALL_USERS_SUCCESS';
export const SEND_TIPS_NOTIFICATION_TO_ALL_USERS_FAILURE = 'SEND_TIPS_NOTIFICATION_TO_ALL_USERS_FAILURE';

export const SEND_MARKETING_NOTIFICATION_TO_ALL_USERS_REQUEST = 'SEND_MARKETING_NOTIFICATION_TO_ALL_USERS_REQUEST';
export const SEND_MARKETING_NOTIFICATION_TO_ALL_USERS_SUCCESS = 'SEND_MARKETING_NOTIFICATION_TO_ALL_USERS_SUCCESS';
export const SEND_MARKETING_NOTIFICATION_TO_ALL_USERS_FAILURE = 'SEND_MARKETING_NOTIFICATION_TO_ALL_USERS_FAILURE';

export const GET_ALL_TIPS_NOTIFICATION_REQUEST = 'GET_ALL_TIPS_NOTIFICATION_REQUEST';
export const GET_ALL_TIPS_NOTIFICATION_SUCCESS = 'GET_ALL_TIPS_NOTIFICATION_SUCCESS';
export const GET_ALL_TIPS_NOTIFICATION_FAILURE = 'GET_ALL_TIPS_NOTIFICATION_FAILURE';

export const SEND_NOTIFICATION_TO_USER_REQUEST = 'SEND_NOTIFICATION_TO_USER_REQUEST';
export const SEND_NOTIFICATION_TO_USER_SUCCESS = 'SEND_NOTIFICATION_TO_USER_SUCCESS';
export const SEND_NOTIFICATION_TO_USER_FAILURE = 'SEND_NOTIFICATION_TO_USER_FAILURE';

export const sendNotificationToAllUsers = (data, awsUrl, sendModalToggle, setNotificationValue) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_NOTIFICATION_TO_ALL_USERS_REQUEST });
            if (data && data.image && data.image.file) {
                const imgUrl = `${process.env.REACT_APP_BASE_URL}/s3/upload/yaadein/musics/v1`;
                const dataImg = {
                    sFileName: data.image.file.name,
                    sContentType: data.image.file.type
                };
                const imgRes = await axios.post(imgUrl, dataImg, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                const surl = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(surl, data.image.file, { headers: { "Content-Type": data.image.file.type } });

                const url = `${process.env.REACT_APP_BASE_URL}/fcm/send/notification/all/v1`;
                const res = await axios.post(url, { ...data, image: sImage }, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: SEND_NOTIFICATION_TO_ALL_USERS_SUCCESS, payload: res.data });
                toast("Push notification send successfully");
                sendModalToggle();
                setNotificationValue({
                    title: "",
                    body: "",
                    image: ""
                });
                try {
                    dispatch({ type: GET_ALL_NOTIFICATION_REQUEST });
                    const url = `${process.env.REACT_APP_BASE_URL}/fcm/all/notification/history/v1`;
                    const res = await axios.get(url, {
                        headers: {
                            Authorization: localStorage.getItem('token')
                        }
                    });
                    dispatch({ type: GET_ALL_NOTIFICATION_SUCCESS, payload: res.data });
                } catch (error) {
                    console.error(error);
                    dispatch({ type: GET_ALL_NOTIFICATION_FAILURE, payload: error.message });
                    toast(error.response.data.message);
                }
            } else {
                const url = `${process.env.REACT_APP_BASE_URL}/fcm/send/notification/all/v1`;
                const res = await axios.post(url, data, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: SEND_NOTIFICATION_TO_ALL_USERS_SUCCESS, payload: res.data });
                toast("Push notification send successfully");
                sendModalToggle();
                setNotificationValue({
                    title: "",
                    body: "",
                    image: ""
                });
                try {
                    dispatch({ type: GET_ALL_NOTIFICATION_REQUEST });
                    const url = `${process.env.REACT_APP_BASE_URL}/fcm/all/notification/history/v1`;
                    const res = await axios.get(url, {
                        headers: {
                            Authorization: localStorage.getItem('token')
                        }
                    });
                    dispatch({ type: GET_ALL_NOTIFICATION_SUCCESS, payload: res.data });
                } catch (error) {
                    console.error(error);
                    dispatch({ type: GET_ALL_NOTIFICATION_FAILURE, payload: error.message });
                    toast(error.response.data.message);
                }
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: SEND_NOTIFICATION_TO_ALL_USERS_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getAllNotifications = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ALL_NOTIFICATION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/fcm/all/notification/history/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_ALL_NOTIFICATION_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ALL_NOTIFICATION_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const sendTipsNotificationToAllUsers = (data, awsUrl, sendModalToggle, setNotificationValue) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_TIPS_NOTIFICATION_TO_ALL_USERS_REQUEST });
            if (data && data.image && data.image.file) {
                const imgUrl = `${process.env.REACT_APP_BASE_URL}/s3/upload/yaadein/musics/v1`;
                const dataImg = {
                    sFileName: data.image.file.name,
                    sContentType: data.image.file.type
                };
                const imgRes = await axios.post(imgUrl, dataImg, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                const surl = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(surl, data.image.file, { headers: { "Content-Type": data.image.file.type } });

                const url = `${process.env.REACT_APP_BASE_URL}/fcm/send/tips-tricks/all/v1`;
                const res = await axios.post(url, { ...data, image: sImage }, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: SEND_TIPS_NOTIFICATION_TO_ALL_USERS_SUCCESS, payload: res.data });
                toast("Tips notification send successfully");
                sendModalToggle();
                setNotificationValue({
                    title: "",
                    body: "",
                    image: ""
                });
                try {
                    dispatch({ type: GET_ALL_TIPS_NOTIFICATION_REQUEST });
                    const url = `${process.env.REACT_APP_BASE_URL}/fcm/all/tips-tricks/history/v1`;
                    const res = await axios.get(url, {
                        headers: {
                            Authorization: localStorage.getItem('token')
                        }
                    });
                    dispatch({ type: GET_ALL_TIPS_NOTIFICATION_SUCCESS, payload: res.data });
                } catch (error) {
                    console.error(error);
                    dispatch({ type: GET_ALL_TIPS_NOTIFICATION_FAILURE, payload: error.message });
                    toast(error.response.data.message);
                }
            } else {
                const url = `${process.env.REACT_APP_BASE_URL}/fcm/send/tips-tricks/all/v1`;
                const res = await axios.post(url, data, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: SEND_TIPS_NOTIFICATION_TO_ALL_USERS_SUCCESS, payload: res.data });
                toast("Tips notification send successfully");
                sendModalToggle();
                setNotificationValue({
                    title: "",
                    body: "",
                    image: ""
                });
                try {
                    dispatch({ type: GET_ALL_TIPS_NOTIFICATION_REQUEST });
                    const url = `${process.env.REACT_APP_BASE_URL}/fcm/all/tips-tricks/history/v1`;
                    const res = await axios.get(url, {
                        headers: {
                            Authorization: localStorage.getItem('token')
                        }
                    });
                    dispatch({ type: GET_ALL_TIPS_NOTIFICATION_SUCCESS, payload: res.data });
                } catch (error) {
                    console.error(error);
                    dispatch({ type: GET_ALL_TIPS_NOTIFICATION_FAILURE, payload: error.message });
                    toast(error.response.data.message);
                }
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: SEND_TIPS_NOTIFICATION_TO_ALL_USERS_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const sendMarketingNotificationToAllUsers = (data, setNotificationValue) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_MARKETING_NOTIFICATION_TO_ALL_USERS_REQUEST });
            if (data && data.image && data.image.file) {
                const imgUrl = `${process.env.REACT_APP_BASE_URL}/s3/upload/yaadein/musics/v1`;
                const dataImg = {
                    sFileName: data.image.file.name,
                    sContentType: data.image.file.type
                };
                const imgRes = await axios.post(imgUrl, dataImg, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                const surl = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(surl, data.image.file, { headers: { "Content-Type": data.image.file.type } });

                const url = `${process.env.REACT_APP_BASE_URL}/fcm/send/marketing-tricks/all/v1`;
                const res = await axios.post(url, { ...data, image: sImage }, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: SEND_MARKETING_NOTIFICATION_TO_ALL_USERS_SUCCESS, payload: res.data });
                toast("Marketing notification send successfully");
                setNotificationValue({
                    title: "",
                    body: "",
                    image: ""
                });
            } else {
                const url = `${process.env.REACT_APP_BASE_URL}/fcm/send/marketing-tricks/all/v1`;
                const res = await axios.post(url, data, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: SEND_MARKETING_NOTIFICATION_TO_ALL_USERS_SUCCESS, payload: res.data });
                toast("Marketing notification send successfully");
                setNotificationValue({
                    title: "",
                    body: "",
                    image: ""
                });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: SEND_MARKETING_NOTIFICATION_TO_ALL_USERS_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const getAllTipsNotifications = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ALL_TIPS_NOTIFICATION_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/fcm/all/tips-tricks/history/v1`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_ALL_TIPS_NOTIFICATION_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ALL_TIPS_NOTIFICATION_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const sendNotificationToUser = (data, sendModalToggle, setNotificationValue) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_NOTIFICATION_TO_USER_REQUEST });
            if (data && data.image && data.image.file) {
                const imgUrl = `${process.env.REACT_APP_BASE_URL}/s3/upload/yaadein/musics/v1`;
                const dataImg = {
                    sFileName: data.image.file.name,
                    sContentType: data.image.file.type
                };
                const imgRes = await axios.post(imgUrl, dataImg, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                const surl = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(surl, data.image.file, { headers: { "Content-Type": data.image.file.type } });

                const url = `${process.env.REACT_APP_BASE_URL}/fcm/admin/send/push/notification/to/user/v1`;
                const res = await axios.post(url, { ...data, image: sImage }, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: SEND_NOTIFICATION_TO_USER_SUCCESS, payload: res.data[0] });
                toast("Push notification send successfully");
                sendModalToggle();
                setNotificationValue({
                    title: "",
                    body: "",
                    image: "",
                    id: ""
                });
            } else {
                const url = `${process.env.REACT_APP_BASE_URL}/fcm/admin/send/push/notification/to/user/v1`;
                const res = await axios.post(url, data, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                dispatch({ type: SEND_NOTIFICATION_TO_USER_SUCCESS, payload: res.data });
                toast("Push notification send successfully");
                sendModalToggle();
                setNotificationValue({
                    title: "",
                    body: "",
                    image: "",
                    id: ""
                });
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: SEND_NOTIFICATION_TO_USER_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};