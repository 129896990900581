import * as occasionActions from "./musicCategory.action";

export const musicCategoryFeatureKey = "musicCategoryInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    musicCategoryList: [],
    musicCategoryListTotal: [],
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case occasionActions.CREATE_MUSIC_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case occasionActions.CREATE_MUSIC_CATEGORY_SUCCESS:
            let createArr = state.musicCategoryList;
            createArr.unshift(payload.data);
            return {
                ...state,
                loading: false,
                musicCategoryList: createArr
            }

        case occasionActions.CREATE_MUSIC_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case occasionActions.UPDATE_MUSIC_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case occasionActions.UPDATE_MUSIC_CATEGORY_SUCCESS:
            let findIndex = state.musicCategoryList.findIndex(o => o._id === payload.data._id);
            state.musicCategoryList.splice(findIndex, 1, payload.data);
            return {
                ...state,
                loading: false
            }

        case occasionActions.UPDATE_MUSIC_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case occasionActions.DELETE_MUSIC_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case occasionActions.DELETE_MUSIC_CATEGORY_SUCCESS:
            const removeArr = state.musicCategoryList.filter(o => o._id !== payload);
            return {
                ...state,
                loading: false,
                musicCategoryList: removeArr
            }

        case occasionActions.DELETE_MUSIC_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case occasionActions.LIST_MUSIC_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case occasionActions.LIST_MUSIC_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                musicCategoryList: payload.data,
                musicCategoryListTotal: payload.total
            }

        case occasionActions.LIST_MUSIC_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};