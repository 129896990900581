import { combineReducers } from 'redux';
import * as adminReducers from "./admin/admin.reducer";
import * as occasionReducers from "./occasion/occasion.reducer";
import * as gifReducers from "./gif/gif.reducer";
import * as imageReducers from "./image/image.reducer";
import * as musicReducers from "./music/music.reducer";
import * as musicCategoryReducers from "./musicCategory/musicCategory.reducer";
import * as userReducers from "./user/user.reducer";
import * as yaadeinReducers from "./yaadein/yaadein.reducer";
import * as notificationReducers from "./notification/notification.reducer";
import * as InstructionReducers from "./Instruction/Instruction.reducer";
import * as SettingReducers from "./setting/Setting.reducer";
import * as PriceReducers from "./price/price.reducer";
import * as TutorialReducers from "./tutorial/tutorial.reducer";

export const rootReducer = combineReducers({
    [adminReducers.adminFeatureKey]: adminReducers.reducer,
    [occasionReducers.occasionFeatureKey]: occasionReducers.reducer,
    [gifReducers.gifFeatureKey]: gifReducers.reducer,
    [imageReducers.imageFeatureKey]: imageReducers.reducer,
    [musicReducers.musicFeatureKey]: musicReducers.reducer,
    [musicCategoryReducers.musicCategoryFeatureKey]: musicCategoryReducers.reducer,
    [userReducers.userFeatureKey]: userReducers.reducer,
    [yaadeinReducers.yaadeinFeatureKey]: yaadeinReducers.reducer,
    [InstructionReducers.InstructionInformationFeatureKey]: InstructionReducers.reducer,
    [SettingReducers.SettingInformationFeatureKey]: SettingReducers.reducer,
    [notificationReducers.notificationFeatureKey]: notificationReducers.reducer,
    [PriceReducers.priceFeatureKey]: PriceReducers.reducer,
    [TutorialReducers.tutorialFeatureKey]: TutorialReducers.reducer,
});