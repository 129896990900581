import * as tutorialActions from "./tutorial.action";

export const tutorialFeatureKey = "tutorialInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    tutorialDetail: []
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {

        case tutorialActions.UPDATE_TUTORIAL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case tutorialActions.UPDATE_TUTORIAL_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case tutorialActions.UPDATE_TUTORIAL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case tutorialActions.LIST_TUTORIAL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case tutorialActions.LIST_TUTORIAL_SUCCESS:
            return {
                ...state,
                loading: false,
                tutorialDetail: payload.data || {},
            }

        case tutorialActions.LIST_TUTORIAL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};