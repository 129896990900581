import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import { store } from "./app/redux/store";
import Spinner from 'react-bootstrap/Spinner';
import "react-toastify/dist/ReactToastify.css";

export const history = createBrowserHistory();
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner animation="border" />}>
      <BrowserRouter history={history}>
        <ToastContainer />
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
