import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const UPDATE_PRICE_REQUEST = "UPDATE_PRICE_REQUEST";
export const UPDATE_PRICE_SUCCESS = "UPDATE_PRICE_SUCCESS";
export const UPDATE_PRICE_FAILURE = "UPDATE_PRICE_FAILURE";

export const LIST_PRICE_REQUEST = "LIST_PRICE_REQUEST";
export const LIST_PRICE_SUCCESS = "LIST_PRICE_SUCCESS";
export const LIST_PRICE_FAILURE = "LIST_PRICE_FAILURE";

export const LIST_PAYMENT_REQUEST = "LIST_PAYMENT_REQUEST";
export const LIST_PAYMENT_SUCCESS = "LIST_PAYMENT_SUCCESS";
export const LIST_PAYMENT_FAILURE = "LIST_PAYMENT_FAILURE";

export const updatePrice = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_PRICE_REQUEST });
            let icon = "";

            const url = `${baseUrl}/price/update/v1`;
            const response = await axios.post(url, { ...data }, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: UPDATE_PRICE_SUCCESS, payload: response.data });
            toast("Price Updated Successfully.");
            try {
                dispatch({ type: LIST_PRICE_REQUEST });
                const url = `${baseUrl}/price/details/admin/v1`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                dispatch({ type: LIST_PRICE_SUCCESS, payload: response.data });
            } catch (error) {
                console.error(error);
                dispatch({ type: LIST_PRICE_FAILURE, payload: error.message });
                toast(error?.response?.data?.message);
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_PRICE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const listPrice = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_PRICE_REQUEST });
            const url = `${baseUrl}/price/details/admin/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: LIST_PRICE_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_PRICE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const listPayment = (page, search) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LIST_PAYMENT_REQUEST });
            const url = `${baseUrl}/stripe/admin/list/v1?skip=${page}&search=${search}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: LIST_PAYMENT_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: LIST_PAYMENT_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};