import * as priceActions from "./price.action";

export const priceFeatureKey = "priceInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    priceDetail: []
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {

        case priceActions.UPDATE_PRICE_REQUEST:
            return {
                ...state,
                loading: true,
                isCreate: false
            }

        case priceActions.UPDATE_PRICE_SUCCESS:
            return {
                ...state,
                loading: false,
                isCreate: true
            }

        case priceActions.UPDATE_PRICE_FAILURE:
            return {
                ...state,
                loading: false,
                isCreate: false,
                errorMessage: payload
            }

        case priceActions.LIST_PRICE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case priceActions.LIST_PRICE_SUCCESS:
            console.log('payload', payload)
            return {
                ...state,
                loading: false,
                priceDetail: payload.data || {},
            }

        case priceActions.LIST_PRICE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: payload
            }

        case priceActions.LIST_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case priceActions.LIST_PAYMENT_SUCCESS:
            console.log('payload', payload)
            return {
                ...state,
                loading: false,
                paymentList: payload?.data?.length > 0 && payload?.data[0]?.results || [],
                paymentCount: payload?.data?.length > 0 && payload?.data[0]?.total
            }

        case priceActions.LIST_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: payload
            }
        default: return state;
    }
};